<template>
    <setting-layout
        :title="$t('pages.user.setting.creator.streams.title')"
        :handle-back-by-emit="true"
        @back="$router.push({name: 'user.setting.creator'})"
    >
        <v-container class="pb-0 overflow-hidden">
            <!-- tabs -->
            <v-row v-if="!user.stream" align="center" justify="center" class="tabs mb-4">
                <v-col cols="6">
                    <v-btn
                        elevation="2"
                        x-large
                        class="tabs__tab"
                        :class="{'tabs__tab--active': tab === 'now'}"
                        @click="tab = 'now'"
                    >
                        {{ $t('pages.user.setting.creator.streams.tabs.now') }}
                    </v-btn>
                </v-col>

                <v-col cols="6">
                    <v-btn
                        elevation="2"
                        x-large
                        class="tabs__tab"
                        :class="{'tabs__tab--active': tab === 'later'}"
                        @click="tab = 'later'"
                    >
                        {{ $t('pages.user.setting.creator.streams.tabs.later') }}
                    </v-btn>
                </v-col>
            </v-row>

            <!-- settings -->
            <div class="settings">
                <!-- date & time -->
                <template v-if="tab === 'later' || user.stream">
                    <div class="settings__item">
                        <div class="settings__item__key mr-2">
                            {{ $t('pages.user.setting.creator.streams.fields.dateTime.title') }}
                        </div>
                        <div style="width: 150px;">
                            <input
                                v-model="starts_at"
                                type="datetime-local"
                                id="futureDateTime"
                                name="futureDateTime"
                                style="width: 230px !important;"
                                :min="minDateTime"
                                required
                                :disabled="!!user.stream"
                            />
                        </div>
                    </div>
                </template>

                <!-- price -->
                <div class="settings__item">
                    <div class="settings__item__key">
                        {{ $t('pages.user.setting.creator.streams.fields.price.title') }}
                    </div>
                    <div style="width: 150px;">
                        <text-field
                            :error-messages="errors.price"
                            placeholder="0-100"
                            type="number"
                            :prepend="currency"
                            v-on:keyup.enter="handleSubmit()"
                            v-model="price"
                            :disabled="!!user.stream"
                        />
                    </div>
                </div>

                <!-- viewers limit -->
                <div class="settings__item">
                    <div class="settings__item__key">
                        {{ $t('pages.user.setting.creator.streams.fields.viewersLimit.title') }}
                    </div>
                    <div style="width: 150px;">
                        <text-field
                            :error-messages="errors.viewers_limit"
                            placeholder="1-100"
                            type="number"
                            v-on:keyup.enter="handleSubmit()"
                            v-model="viewers_limit"
                            :disabled="!!user.stream"
                        />
                    </div>
                </div>

                <!-- Goal settings -->
                <v-row>
                    <v-col cols="12">
                        <span class="font-weight-bold">
                            {{ $t('pages.user.setting.creator.streams.fields.setGoal.title') }}
                        </span>
                    </v-col>

                    <v-col cols="7">
                        <text-field
                            :error-messages="errors.goal_name"
                            placeholder="Name"
                            v-model="goal_name"
                            :disabled="!!user.stream"
                        />
                    </v-col>
                    <v-col cols="5">
                        <text-field
                            :error-messages="errors.goal_price"
                            placeholder="Price"
                            :prepend="currency"
                            v-model="goal_price"
                            :disabled="!!user.stream"
                        />
                    </v-col>
                </v-row>

                <!-- is free for subscribers -->
                <div class="settings__item" style="margin: -10px 0;">
                    <div class="settings__item__key" style="line-height: 62px">
                        {{ $t('pages.user.setting.creator.streams.fields.isFreeForSubscribers.title') }}
                    </div>
                    <v-checkbox
                        dense
                        :value="is_free_for_subscribers"
                        color="primary"
                        v-model="is_free_for_subscribers"
                        :disabled="!!user.stream"
                    />
                </div>

                <!-- is free for followers -->
                <div class="settings__item" style="margin: -10px 0;">
                    <div class="settings__item__key" style="line-height: 62px">
                        {{ $t('pages.user.setting.creator.streams.fields.isFreeForFollowers.title') }}
                    </div>
                    <v-checkbox
                        dense
                        :value="is_free_for_followers"
                        color="primary"
                        v-model="is_free_for_followers"
                        :disabled="!!user.stream"
                    />
                </div>

                <!-- tickets bought -->
                <div v-if="user.stream && !isAvailableToGoLive" class="settings__item">
                    <div class="settings__item__key">
                        {{ $t('pages.user.setting.creator.streams.fields.ticketsSold.title') }}
                    </div>
                    <div class="grey--text" style="line-height: 52px">
                        {{ user.stream.stream_tickets.length }} / {{ user.stream.viewers_limit }}
                    </div>
                </div>

                <template v-if="user.streams">
                    <hr class="my-2" style="opacity: 0.25"/>

                    <div class="settings__item">
                        <div class="settings__item__key">
                            {{ $t('pages.user.setting.creator.streams.fields.rating.title') }}
                        </div>

                        <div class="rating"
                             :class="averageStreamerRating < 50
                                ? 'rating--red'
                                : averageStreamerRating < 70
                                    ? 'rating--orange'
                                    : averageStreamerRating < 90
                                        ? 'rating--yellow'
                                        : 'rating--green'
                        ">
                            <svg>
                                <circle cx="25" cy="25" r="20"></circle>
                                <circle cx="25" cy="25" r="20" :style="`--percent: ${averageStreamerRating}`"></circle>
                            </svg>
                            <div class="title">
                                <div>{{ averageStreamerRating < 50 ? '😞' : averageStreamerRating < 70 ? '😐' : averageStreamerRating < 90 ? '😃' : '😍' }}</div>
                            </div>
                        </div>
                    </div>
                </template>
            </div>

            <!-- footer -->
            <div class="submit my-4">
                <div class="grey--text caption mb-6 text-center">{{ $t('pages.user.setting.creator.streams.rules.title') }}<a href="/stream-rules" target="_blank">{{ $t('pages.user.setting.creator.streams.rules.name') }}</a>.</div>

                <div
                    v-if="!!user.stream"
                    style="background: rgba(255,255,255,0.1); text-overflow: ellipsis; display: flex; align-items: center; border-radius: 8px;"
                    class="px-4 py-2 mb-6 cursor-pointer"
                    @click="copyToClipboard(baseUrl + `/user/${user.slug}/stream/${user.stream.token}`)"
                >
                    <div style="text-overflow: ellipsis; white-space: nowrap; overflow: hidden">
                        {{ baseUrl + `/user/${user.slug}/stream/${user.stream.token}` }}
                    </div>

                    <div class="ml-4" style="display: flex; align-items: center">
                        <icon-done v-if="isCopied"/>
                        <icon-copy v-else/>
                    </div>
                </div>

                <div :style="`display: grid; grid-template-columns: ${user.stream ? '1fr 64px' : '1fr'}`">
                    <v-btn
                        elevation="2"
                        x-large
                        class="submit__btn"
                        :class="!user.stream || (user.stream && isAvailableToGoLive) ? 'submit__btn--active' : ''"
                        @click="!user.stream ? handleSubmit() : handleStreamOpen()"
                    >
                        <template v-if="user.stream || tab === 'now'">
                            {{ $t('pages.user.setting.creator.streams.actions.goLive') }}
                        </template>

                        <template v-else>
                            {{ $t('pages.user.setting.creator.streams.actions.schedule') }}
                        </template>
                    </v-btn>

                    <v-btn
                        v-if="user.stream"
                        elevation="2"
                        x-large
                        style="background: rgba(255,255,255,0.1); text-overflow: ellipsis; display: flex; align-items: center; min-width: 48px; width: 48px; height: 48px; margin-left: 16px"
                        @click="handleStreamTerminate()"
                    >
                        <icon-delete style="transform: scale(0.7)"/>
                    </v-btn>
                </div>
            </div>

            <stream-not-started-yet
                v-if="showStreamNotStartedYetDialog"
                :date="formatDate(
                        convertUTCDatetimeToLocal(user.stream.starts_at)
                    )
                "
                :difference="streamStartsAtDifferenceMessage"
                @close="showStreamNotStartedYetDialog = false"
            />
        </v-container>
    </setting-layout>
</template>

<script>
import SettingLayout from "@/layouts/app/profile/SettingLayout";
import GradientButton from "@/components/app/button/GradientButton";
import TextField from "@/components/app/form/TextField.vue";
import {CURRENCY_SYMBOL} from "@/configs/constants";
import IconGreat from "@/assets/images/icons/info.svg?inline";
import IconCopy from "@/assets/images/icons/copy.svg?inline";
import IconDone from "@/assets/images/icons/done.svg?inline";
import IconOpenInNew from "@/assets/images/icons/open-in-new.svg?inline";
import IconDelete from "@/assets/images/icons/delete.svg?inline";
import StreamNotStartedYet from "@/components/app/dialogs/StreamNotStartedYetDialog.vue";
import {mapState} from "vuex";
import {convertUTCDatetimeToLocal, formatDate, parseDate, toLocalISOString} from "@/helpers/functions";

export default {
    name: "Streams",
    data() {
        return {
            tab: 'now',
            errors: {},
            currency: CURRENCY_SYMBOL,
            price: null,
            viewers_limit: 100,
            starts_at: null,
            is_free_for_subscribers: true,
            is_free_for_followers: true,

            isCopied: false,
            copyToClipboardTimeout: null,

            showStreamNotStartedYetDialog: false,
            goal_price: null,
            goal_name: '',
        }
    },
    computed: {
        minDateTime() {
            const now = new Date();
            return now.toISOString().slice(0, 16);
        },
        baseUrl() {
            return window.location.origin;
        },
        ...mapState({
            user: state => state.user.user.data,
        }),
        isAvailableToGoLive() {
            if (!this.user || !this.user.stream || !this.starts_at) return false;
            let starts_at = parseDate(this.user.stream.starts_at);
            const diff = starts_at.getTime() - new Date().getTime();
            return diff <= 10 * 60 * 1000 && diff >= 0 || diff < 0;
        },
        streamStartsAtDifferenceMessage() {
            let starts_at = parseDate(this.user.stream.starts_at);
            const diff = starts_at.getTime() - new Date().getTime();

            const days = diff / (1000 * 60 * 60 * 24);
            const hours = diff / (1000 * 60 * 60);
            const minutes = diff / (1000 * 60);

            if (days >= 1) {
                return `${Math.floor(days)} ${this.$i18n.t('dialogs.days')}`;
            } else if (hours >= 1) {
                return `${Math.floor(hours)} ${this.$i18n.t('dialogs.hours')}`;
            } else if (minutes >= 0) {
                return `${Math.floor(minutes)} ${this.$i18n.t('dialogs.minutes')}`;
            }
        },
        averageStreamerRating() {
            if (!this.user.streamsRatings) return 0;
            const sum = this.user.streamsRatings.reduce((accumulator, currentObject) => accumulator + currentObject.rate, 0);
            return sum / this.user.streamsRatings.length;
        },
    },
    methods: {
        formatDate,
        convertUTCDatetimeToLocal,

        setStreamData() {
            this.price = this.user.stream.price;
            this.viewers_limit = this.user.stream.viewers_limit;
            this.is_free_for_subscribers = this.user.stream.is_free_for_subscribers;
            this.is_free_for_followers = this.user.stream.is_free_for_followers;
            this.starts_at = toLocalISOString(convertUTCDatetimeToLocal(this.user.stream.starts_at));
            this.goal_name = this.user.stream.stream_goals[0]?.goal_name;
            this.goal_price = this.user.stream.stream_goals[0]?.goal_price;
        },

        resetStreamData() {
            this.price = null;
            this.viewers_limit = 100;
            this.is_free_for_subscribers = true;
            this.is_free_for_followers = true;
            this.starts_at = null;
            this.goal_name = null;
            this.goal_price = null;
        },

        validate() {
            this.errors = {};

            if ((!this.price && this.price !== 0) || this.price < -1 || this.price > 100) {
                this.errors.price = 'Price should be in range of 0 - 100'
                return false;
            }

            if (!this.viewers_limit || this.viewers_limit < 0 || this.viewers_limit > 100) {
                this.errors.price = 'Viewers limit should be in range of 0 - 100'
                return false;
            }

            if (this.goal_price && !this.goal_name) {
                this.errors.goal_name = 'Goal name is required for stream goal';
                return false;
            }

            if (this.goal_name && (!this.goal_price)) {
                this.errors.goal_price = 'Goal price is required for stream goal';
                return false;
            }

            if (this.goal_name && this.goal_name.length > 20) {
                this.errors.goal_name = 'Goal name should not be more than 20 Characters';
                return false;
            }

            return true;
        },

        handleSubmit() {
            const isValid = this.validate();
            if (!isValid) {
                return false;
            }

            axios.post('/api/stream', {
                price: this.price,
                viewers_limit: this.viewers_limit,
                starts_at: this.tab === 'later' ? this.starts_at : null,
                is_free_for_subscribers: this.is_free_for_subscribers,
                is_free_for_followers: this.is_free_for_followers,
                utc_offset: new Date().getTimezoneOffset() / 60,
                goal_name: this.goal_name,
                goal_price: this.goal_price,
            }).then((response) => {
                this.user.stream = response.data.stream;
                if (this.tab === 'now') {
                    this.starts_at = toLocalISOString(new Date());
                }
            }).catch((error) => {
                console.log(error)
            })
        },

        handleStreamOpen() {
            if (!this.isAvailableToGoLive) {
                this.showStreamNotStartedYetDialog = true;
                return;
            }

            this.$router.push({name: 'stream', params: {slug: this.user.slug, token: this.user.stream.token}})
        },

        async sendStreamSignal(data) {
            return await axios.post(`/api/stream/${this.user.stream.id}/signal`, data)
                .catch((error) => {
                    console.log(error)
                });
        },

        handleStreamTerminate() {
            this.sendStreamSignal({
                is_live: false,
                ended_at: new Date()
            }).then(() => {
                this.resetStreamData();
                axios.delete(`${process.env.VUE_APP_MEDIA_SERVER_URL}/stream`, {
                    token: this.user.stream.token,
                })
                    .catch((error) => {
                        console.log(error)
                    });
                this.user.stream = null;
            });
        },

        async copyToClipboard (string) {
            clearTimeout(this.copyToClipboardTimeout);

            try {
                await navigator.clipboard.writeText(string);
                this.isCopied = true;

                this.copyToClipboardTimeout = setTimeout(() => {
                    this.isCopied = false;
                    clearTimeout(this.copyToClipboardTimeout);

                }, 3000)
            } catch (err) {
                console.error('Failed to copy: ', err);
            }
        },
    },

    mounted() {
        if (this.user.streams_restricted_at) {
            return this.$router.push({name: 'user.setting.creator'})
        }

        if (this.user.stream) {
            this.setStreamData();
        }
    },

    components: {
        TextField,
        GradientButton,
        SettingLayout,
        IconGreat,
        IconCopy,
        IconDone,
        IconOpenInNew,
        IconDelete,
        StreamNotStartedYet
    }
}
</script>

<style scoped lang="scss">
@import '@/sass/modules/_variables';

.tabs {
    .tabs__tab {
        background: rgba(237, 237, 237, 0.1) !important;
        color: rgba(255, 255, 255, 0.50);
        width: 100%;
        height: 48px;
        transition: .3s;

        &.tabs__tab--active {
            background: $accent-background !important;
            color: white;
        }
    }
}

.settings {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .settings__item {
        display: flex;
        gap: 20px;

        .settings__item__key {
            font-weight: 600;
            line-height: 52px;
        }

        input {
            height: 52px;
            line-height: 52px;
            padding: 0 17px;
            color: #f5f4e5 !important;
            border-radius: 6px;
            background-color: rgba(237, 237, 237, 0.1);
            border-color: rgba(237, 237, 237, 0.1);
            box-shadow: 0p 3px 10px -2px rgba(85, 85, 85, 0.08),
                        0px 2px 20px 0px rgba(85, 85, 85, 0.06),
                        0px 1px 30px 0px rgba(85, 85, 85, 0.03);
            color-scheme: dark;
        }
    }
}

.submit {
    bottom: 0;
    left: 0;
    width: 100%;

    .submit__btn {
        background: rgba(237, 237, 237, 0.1) !important;
        color: rgba(255, 255, 255, 0.50);
        //width: 100%;
        height: 48px;
        transition: .3s;

        &.submit__btn--active {
            background: $accent-background !important;
            color: white;
        }
    }
}


label {
    display: block; /* The label is on its own line */
}

select {
    background-color: rgba(237, 237, 237, 0.1);
    border-color: rgba(237, 237, 237, 0.1);
    color: #fff;
    border-radius: 6px;
    padding: 10px 15px;
    width: 100px;
    box-sizing: border-box;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

/* You can add a background image for a dropdown arrow if needed */
select {
    background-image: url('data:image/svg+xml;utf8,<svg fill="white" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="M7 10l5 5 5-5z"/></svg>');
    background-position: right 10px center;
    background-repeat: no-repeat;
    background-size: 12px;
}

/*
 * rating
 */
.rating {
    position: relative;
    width: 50px;
    height: 50px;
    margin-top: 1px;

    svg {
        position: relative;
        width: 50px;
        height: 50px;
        transform: rotate(-90deg);

        circle {
            width: 100%;
            height: 100%;
            fill: none;
            stroke-width: 6;
            stroke-linecap: round;

            &:last-of-type {
                stroke-dasharray: 120px;
                stroke-dashoffset: calc(120px - (120px * var(--percent)) / 100);
            }
        }
    }

    .title {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}

.rating--red {
    svg {
        circle {
            stroke: rgba(253, 18, 18, 0.25);
            &:last-of-type {
                stroke: #fd1212;
            }
        }
    }
}
.rating--orange {
    svg {
        circle {
            stroke: rgba(253, 83, 18, 0.25);
            &:last-of-type {
                stroke: #FD5312;
            }
        }
    }
}
.rating--yellow {
    svg {
        circle {
            stroke: rgba(253, 214, 18, 0.25);
            &:last-of-type {
                stroke: #fde912;
            }
        }
    }
}
.rating--green {
    svg {
        circle {
            stroke: rgba(18, 253, 38, 0.25);
            &:last-of-type {
                stroke: #60fd12;
            }
        }
    }
}
</style>
